/**
 * import feature from '@app/features';
 *
 * return feature.canProject
 */

import {company, salesinvoice} from '@app/settings'
import string from '@lib/string'
import bool from '@lib/bool'
import Constants from '@app/consts'
import user from '@app/user'
import {tendergroup as tgList, creditrestriction} from '@app/list';

class clsFeature {

    // Shortcuts to the modules
    get canPurchase()        { return true && this.canModule(Constants.modules.PURCHASEINVOICES); }
    get canGo2UBL()          { return true && this.canModule(Constants.modules.GO2UBL); }
    get canPaymentOrder()    { return true && this.canModule(Constants.modules.PAYMENTORDERS); }
    get canTender()          { return true && this.canModule(Constants.modules.TENDERS); }
    get canSalesInvoice()    { return true && this.canModule(Constants.modules.SALESINVOICES); }
    get canProject()         { return true && this.canModule(Constants.modules.PROJECTS); }
    get canHours()           { return true && /*this.hasParamFF(27) && */ this.canModule(Constants.modules.HOURS); }
    get canManday()          { return true && this.canModule(Constants.modules.MANDAYS); }
    get canPeriodicInvoice() { return true && /*this.hasParamFF(31) && */ this.canModule(Constants.modules.PERIODICINVOICES); }
    get canPlanning()        { return true && this.canModule(Constants.modules.PLANNING); }
    get canSalesOrder()      { return true && this.canModule(Constants.modules.SALESORDERS); }
    get canWorkOrder()       { return true && this.hasParamFF(Constants.modules.WORKORDERS); } // this.canModule(Constants.modules.WORKORDERS); }
    get canMolliePayment()   { return true && company.hasMollie; } 
    get canMollieIncasso()   { return true && this.canModule(Constants.modules.MOLLIE_INCASSO)  || this.hasParamFF(Constants.modules.MOLLIE_INCASSO); } 
    get canBulkReminder()    { return true && this.canModule(Constants.modules.SALESINVOICES) /* && this.hasParamFF(6543); */ } 

    // Other features
    get canGAccount()       { return true && string.isNotEmpty(company.ibang); }
    get canSalesBookdate()  { return true && bool.isTrue(salesinvoice.use_bookdate); }
    get canDirtyTrack()     { return true && false; } // alert when invoice is closed without saving. It is not bulletproof yet.
    get canTenderGroup()    { return true && tgList.list.length>1; }
    get canConsumerPrice()  { return true && bool.isTrue(salesinvoice.use_consumer_prices); }
    get canCreditRestriction() { return true && creditrestriction?.list?.length>=1; }


    /**
     * Is the given module available for the company of the current user.
     * This maps directly to the user modules but allows for exceptions for modules which are e.g. in development.
     * Usage: 
     *  
     * import feature from '@app/features'
     *
     * <div v-if="feature.canProject"> Projecten!</div>
     * 
     * 
     * @param {} m 
     * @returns 
     */
    canModule(m) {
        switch (m) {
            case Constants.modules.WORKORDERS:
                return this.hasParamFF(m); 
            default:
                break;
        }
        return user.hasModule(m);
    }

    paramFF = null;
    constructor() {
        var urlParams = new URLSearchParams(window.location.search);  
        this.paramFF = urlParams.get('ff');
    }

    hasParamFF(ff) {
        if(!this.paramFF) {
            return false;
        }
        return `${this.paramFF}`.indexOf(`${ff}`) >=0;
    }


} 
  
export default clsFeature;
 